import React, { Component } from 'react';
import AuthService from "../services/auth.service";
import { 
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { findLastSync } from '../store/actions/imports.action';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    lastSync: state.imports.lastSync
  };
}

const mapDispatchToProps = (dispatch) => ({
  findLastSync: () => {dispatch(findLastSync())}
});

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: null,

      showAdminBoard: false,
      currentUser: undefined
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminBoard: user.roles.includes("ROLE_ADMIN")
      });

      this.props.findLastSync();
    }
  }
  
  logOut = () => {
    AuthService.logout();
    this.props.history.push('/login');
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const { currentUser, showAdminBoard } = this.state;

    const lastSyncDate = this.state.currentUser 
      ? <>Datos actualizados al {this.props.lastSync ? this.props.lastSync : '' }</>
      : '';


    return (
      <>
        <Navbar className="sticky-top" dark expand="md">
          <NavbarBrand to={"/"}>
            <img src="/images/logo.png" height="35"
                alt="URUDOR" />
          </NavbarBrand>
          
          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>

            <Nav className="mr-auto" navbar>
              {currentUser && (
                <NavItem className="nav-item">
                  <NavLink href="/home">Inicio</NavLink>
                </NavItem>
              )}

              {showAdminBoard && (
                <NavItem>
                  <NavLink href="/admin">Admin Board</NavLink>
                </NavItem>
              )}
              
            </Nav>

            <Nav>
              {
              lastSyncDate
              }
            </Nav>
            
            <Nav className="ml-auto" navbar>
              {currentUser ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {currentUser.username}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="/profile">
                      Perfil
                    </DropdownItem>
                    <DropdownItem>
                      Cambiar Password
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={this.logOut}>
                      Salir
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              
              ) : (
                <>
                  <NavItem>
                    <NavLink href="/login">Entrar</NavLink>
                  </NavItem>

                  {/* <NavItem>
                    <NavLink href="/register">Registrarse</NavLink>
                  </NavItem> */}
                </>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));