import React, { Component } from 'react';
import { getUsers, deleteUser, getRoles } from '../../store/actions/user.action';
import { Table, Breadcrumb, BreadcrumbItem, Container, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import Waiting from '../custom/waiting.component';
import BoxError from '../custom/box-error.component';
import BoxSuccess from '../custom/box-success.component';
import AdminDeleteUser from './admin-delete-user';
import { RECEIVED } from '../../app-config';
import AdminUpdatePassword from './admin-update-password';
import AdminUserRoles from './admin-user-roles';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

const mapStateToProps = state => {
  return {
    users: state.adminUser.users,
    status: state.adminUser.status,
    error: state.adminUser.error,
    message: state.adminUser.message
  }
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => {dispatch(getUsers())},
  getRoles: () => {dispatch(getRoles())},
  deleteUser: () => {dispatch(deleteUser())}
});

class AdminUsers extends Component {

  componentDidMount() {
    this.props.getUsers();
    this.props.getRoles();
  }

  renderUsersRow() {
    if (this.props.users) {
      return this.props.users.map(user => 
        <tr key={user.id}>
          <th scope="row">{user.id}</th>
          <td>{user.roles.filter(r => r === "ROLE_ADMIN").length > 0 ? <FontAwesomeIcon icon={faUserShield} /> : ""}</td>
          <td>{user.username}</td>
          <td>{user.email}</td>
          <td className="d-flex justify-content-end">
            <Button className="mr-2" color="primary" tag="a" href={"/admin/users/edit/" + user.id}>
              <FontAwesomeIcon icon={faEdit} /> 
            </Button>
            <AdminUpdatePassword user={user} />
            <AdminUserRoles user={user} />
            <AdminDeleteUser userId={user.id} />
          </td>
        </tr>
      );
    }
  }

  renderUsersTable() {
    if (this.props.status === RECEIVED) {
      return (
        <Table className="table" size="sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th></th>
              <th scope="col">Username</th>
              <th scope="col">email</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.renderUsersRow()}
          </tbody>
        </Table>
      );
    }
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/admin">Admin</a></BreadcrumbItem>
          <BreadcrumbItem active>Usuarios</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  render() {
    return (
      <>
        { this.renderBreadcrumb() }

        <Waiting status={this.props.status}/>
        <BoxError status={this.props.status} error={this.props.error} />
        <BoxSuccess status={this.props.status} message={this.props.message}/>

        <Container>
          <Row className="mb-2">
            <Col className="d-flex justify-content-start">
              <h3>Usuarios</h3>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button color="primary" tag="a" href="/admin/users/new">
                Nuevo
              </Button>
            </Col>
          </Row>
          <Row>
            {this.renderUsersTable()}
          </Row>
        </Container>
      </>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);