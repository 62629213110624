import React from 'react';
import { Row, Col, UncontrolledAlert } from 'reactstrap';
import { RECEIVED } from '../../app-config';

const BoxSuccess = props => {
  if (props.status === RECEIVED && props.message) {
    return (
      <Row md={12}>
        <Col>
          <UncontrolledAlert color="success">
            {props.message}
          </UncontrolledAlert>
        </Col>
      </Row>
    );
  } else {
    return (<></>);
  }
}

export default BoxSuccess;