import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Row, FormGroup, Label, Col} from "reactstrap";
import { getZafras, getProveedores, getClientes, getVariedades, getVapores } from '../../store/actions/urudor.action';
import { connect } from 'react-redux';
import Waiting from '../custom/waiting.component';
import BoxError from '../custom/box-error.component';
import BoxSuccess from '../custom/box-success.component';
import SelectVapores from './select-vapores.component';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DatePicker from '../custom/date-picker.component';
import MyButton from '../custom/my-button.component';
import { format } from 'date-fns';
import BoardLeftMenu from './board-left-menu.component';
import { REPORT } from '../../app-config';

const mapStateToProps = state => {
  return {
    zafras: state.urudor.zafras,
    proveedores: state.urudor.proveedores,
    clientes: state.urudor.clientes,
    variedades: state.urudor.variedades,
    vapores: state.urudor.vapores,
    status: state.urudor.status,
    error: state.urudor.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getZafras: () => {dispatch(getZafras())},
  getProveedores: (zafra) => {dispatch(getProveedores(REPORT.ESTADOS_DE_CUENTAS_DETALLADOS, zafra))},
  getClientes: (zafra) => {dispatch(getClientes(REPORT.ESTADOS_DE_CUENTAS_DETALLADOS, zafra))},
  getVariedades: (zafra) => {dispatch(getVariedades(REPORT.ESTADOS_DE_CUENTAS_DETALLADOS, zafra))},
  getVapores: (zafra) => {dispatch(getVapores(zafra))},
});

class ReportEstadoDeCuentaDetallado extends Component {

  initialValues = {
    selectZafra: '',
    selectProveedor: '',
    selectCliente: '',
    selectVariedad: '',
    selectVapor: '',
    selectSaldoCero: '',
    selectFechaHasta: new Date(),
    selectFilter: 'DETALLE_Y_TOTALES'
  };

  componentDidMount() {
    this.props.getZafras();

    setTimeout(() => {
      const currentYear = this.props.zafras[0]
        ? this.props.zafras[0].codigo 
        : new Date().getFullYear();
      this.props.getProveedores(currentYear);
      this.props.getClientes(currentYear);
      this.props.getVariedades(currentYear);
      this.props.getVapores(currentYear);
    }, 1000);
   
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem>Estado de cuenta detallado</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  onChangeZafra = ({target}) => {
    this.props.getProveedores(target.value);
    this.props.getClientes(target.value);
    this.props.getVariedades(target.value);
    this.props.getVapores(target.value);
    this.initialValues.selectZafra = target.value;
    this.setState({ state: this.state }); // hacks to re-render the component!
  }

  onChangeVapor = (target) => {
    this.initialValues.selectVapor = target.codigo;
    this.setState({ state: this.state }); // hacks to re-render the component!
  }

  handleSubmit = (form) => {

    form.selectZafra = form.selectZafra || this.props.zafras[0].codigo;
    form.selectProveedor = form.selectProveedor || this.props.proveedores[0].codigo;
    form.selectCliente = form.selectCliente || this.props.clientes[0].codigo;
    form.selectVariedad = form.selectVariedad || this.props.variedades[0].codigo;
    form.selectSaldoCero = form.selectSaldoCero || 0;
    form.selectFilter = form.selectFilter || 0;
    form.selectVapor = (form.selectVapor === '' ? 0 : form.selectVapor);

    const fechaHasta = format(form.selectFechaHasta, "dd-MM-yyyy");

    this.props.history.push("/user/report/estadodecuentadetallado"
      + "/" + form.selectZafra 
      + "/" + form.selectProveedor
      + "/" + form.selectCliente
      + "/" + form.selectVariedad
      + "/" + form.selectVapor
      + "/" + fechaHasta
      + "/" + form.selectSaldoCero
      + "/" + form.selectFilter
    );
  }

  validateForm = values => {
    const errors = {};

    if (!values.selectFechaHasta) {
      errors.selectFechaHasta = "Tiene que tener un valor";
    }

    return errors;
  }

  renderFormFilter = () => {
    return (
      <Formik
          enableReinitialize={true}
          initialValues={this.initialValues}
          validate={this.validateForm}
          onSubmit={
            (values) => {
              this.handleSubmit(values);
            }
          }
        >
          <Form>
            <Row>
              <Col sm={6} md={3}>
                <FormGroup>
                  <Label for="selectZafra">Zafra</Label>
                  
                  <Field as="select" name="selectZafra" className="form-control" onChange={this.onChangeZafra}>
                    {this.props.zafras.map(zafra => 
                      <option key={zafra.codigo} value={zafra.codigo}>{zafra.codigo}</option>)}
                  </Field>
                </FormGroup>
              </Col>
              
              <Col sm={6} md={3}>
                <FormGroup>
                  <Label for="selectProveedor">Proveedor</Label>
                  
                  <Field as="select" name="selectProveedor" className="form-control">
                    {this.props.proveedores.map(proveedor => 
                      <option key={proveedor.codigo} value={proveedor.codigo}>{proveedor.nombre}</option>)}
                  </Field>
                </FormGroup>
              </Col>

              <Col sm={6} md={3}>
                <FormGroup>
                  <Label for="selectCliente">Cliente</Label>
                  
                  <Field as="select" name="selectCliente" className="form-control">
                    {this.props.clientes.map(cliente => 
                      <option key={cliente.codigo} value={cliente.codigo}>{cliente.nombre}</option>)}
                  </Field>
                </FormGroup>
              </Col>

              <Col sm={6} md={3}>
                <FormGroup>
                  <Label for="variedadSelect">Variedad</Label>

                  <Field as="select" name="selectVariedad" className="form-control">
                    {this.props.variedades.map(variedad =>
                      <option key={variedad.codigo} value={variedad.codigo}>{variedad.nombre}</option>)}
                  </Field>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={6} md={2}>
                <FormGroup>
                  <Label for="selectVapor">Vapor</Label>
                
                  <Row>
                    <Col xs={4}>
                      <SelectVapores vapores={this.props.vapores} onChange={this.onChangeVapor}/> 
                    </Col>
                    <Col xs={8}>
                      <Field name="selectVapor" className="form-control" />
                      <ErrorMessage name="selectVapor" component="div" className="text-danger" />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              
              <Col sm={6} md={2}>
                <FormGroup>
                  <Label for="selectSaldoCero">Saldo Cero</Label>

                  <Field as="select" name="selectSaldoCero" className="form-control">
                    <option key={0} value={0}>NO</option>
                    <option key={1} value={1}>SI</option>
                  </Field>
                </FormGroup>
              </Col>

              <Col sm={6} md={3}>
                <FormGroup>
                  <Label for="selectFilter">Detalle o Totales</Label>

                  <Field as="select" name="selectFilter" className="form-control">
                    <option key={0} value={'DETALLE_Y_TOTALES'}>Detalle y Totales</option>
                    <option key={1} value={'TOTALES_POR_CLIENTES'}>Solo Totales por Cliente/Prov. Fruta</option>
                    <option key={2} value={'TOTALES_POR_PROVEEDOR'}>Solo Totales por Proveedor Fruta</option>
                  </Field>
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <DatePicker label='Hasta Fecha' name="selectFechaHasta" />
                </FormGroup>
              </Col>

            </Row>

            <Row className="form-group">
              <Col className="d-flex justify-content-end">
                <MyButton 
                  type="submit"
                  color="primary"
                  colorSpinner="light"
                  value="Confirmar"
                  status={this.props.status}
                  margin="ml-2"
                  />
              </Col>
            </Row>

          </Form>
        </Formik>
    );
  }

  render() {

    return (
      <>
        <Row>
          <Col md="3">
            <BoardLeftMenu />
          </Col>
          <Col md="9">
            <Row>
            {this.renderBreadcrumb()}
            </Row>

            <Row>
              <Waiting status={this.props.status}/>
              <BoxError status={this.props.status} error={this.props.error} />
              <BoxSuccess status={this.props.status} message={this.props.message} />
            </Row>

            <Row>
              <Col>
                {this.renderFormFilter()}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportEstadoDeCuentaDetallado);