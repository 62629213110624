import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Row, Table } from 'reactstrap';
import { getEstadosDeCuentasConsolidados } from '../../store/actions/urudor.action';
import BoxError from '../custom/box-error.component';
import Waiting from '../custom/waiting.component';
import { format } from 'date-fns';
import { formatNumber } from '../utils/formatNumber';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

const mapStateToProps = state => {
  return {
    estadosDeCuentasConsolidados: state.urudor.estadosDeCuentasConsolidados,
    status: state.urudor.status,
    error: state.urudor.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEstadosDeCuentasConsolidados: (zafra, codigoProveedor, codigoCliente, codigoMercado, codigoVapor, saldo, soloTotales) => 
    {dispatch(getEstadosDeCuentasConsolidados(zafra, codigoProveedor, codigoCliente, codigoMercado, codigoVapor, saldo, soloTotales))}
});

class ResultEstadoDeCuentaConsolidado extends Component {

  zafra = null;
  proveedor = null;
  cliente = null;
  mercado = null;
  vapor = null;
  fecha = null;
  saldoCero = null;
  soloTotales = null;

  constructor(props) {
    super(props);

    this.zafra = this.props.match.params.zafra;
    this.proveedor = this.props.match.params.proveedor;
    this.cliente = this.props.match.params.cliente;
    this.mercado = this.props.match.params.mercado;
    this.vapor = this.props.match.params.vapor;
    this.saldoCero = this.props.match.params.saldocero;
    this.soloTotales = this.props.match.params.soloTotales;

    console.log(this.zafra + " - " + this.proveedor + " - " + this.cliente + " - " + this.mercado + " - " + this.vapor + " - " + this.saldoCero + " - " + this.soloTotales);

    this.props.getEstadosDeCuentasConsolidados(
      this.zafra, this.proveedor, this.cliente, this.mercado, this.vapor, this.saldoCero, this.soloTotales);
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2 ml-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/user/report/estadodecuentaconsolidado">Estado de cuenta consolidado</a></BreadcrumbItem>
          <BreadcrumbItem>Informe</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  renderRow = (row) => {
    return (
      <tr>
        <td className="text-right">
          {row.vapor.codigo}
        </td>

        <td className="text-nowrap">
          {row.vapor.nombreBarco}
        </td>

        <td>
          {formatNumber(Number(row.factura), 0)}
        </td>

        <td className="text-nowrap">
          {row.mercado.nombre}
        </td>

        <td className="text-nowrap">
          {row.cliente.nombre}
        </td>

        <td className="text-right">
          {formatNumber(row.tonsNetas, 2)}
        </td>

        <td className="text-right">
          {formatNumber(row.facturas, 2)}
        </td>

        <td className="text-right">
          {formatNumber(row.liqMas)}
        </td>

        <td className="text-right">
          {formatNumber(row.liqMenos)}
        </td>

        <td className="text-right">
          {formatNumber(row.notaCredito)}
        </td>

        <td className="text-right">
          {formatNumber(row.facturaCompra)}
        </td>

        <td className="text-right">
          {formatNumber(row.total)}
        </td>

        <td className="text-right">
          {formatNumber(row.pagos)}
        </td>

        <td className="text-right">
          {row.cuentaSocio ? formatNumber(Number(row.cuentaSocio)) : ''}
        </td>

        <td className="text-right">
          {row.gastos ? formatNumber(row.gastos) : ''}
        </td>

        <td className="text-right">
          {formatNumber(row.otros)}
        </td>

        <td className="text-right">
          {formatNumber(row.total2)}
        </td>

        <td className="text-right">
          {formatNumber(row.saldo)}
        </td>

        <td className="text-right text-nowrap">
          {this.isValidDate(Date.parse(row.fechaProbable + 'T00:00:00')) 
            ? format(Date.parse(row.fechaProbable + 'T00:00:00'), "dd/MM/yyyy") : ''}
        </td>
        
      </tr>
    );
  }

  isValidDate = (date) => {
    if (date < Date.parse('2000-01-01T00:00:00')) {
      return false;
    }

    return true;
  }

  renderTotalRow = (row) => {
    return (
      <tr className="text-nowrap font-weight-bold normal-grey">
        <td colSpan={5}>
          Total {row.rowType === 'TOTAL_PROVEEDOR' ? row.proveedor.nombre : ''}
        </td>

        <td className="text-right">
          {row.tonsNetas.toLocaleString()}
        </td>

        <td className="text-right">
          {row.facturas.toLocaleString()}
        </td>

        <td className="text-right">
          {row.liqMas}
        </td>

        <td className="text-right">
          {row.liqMenos}
        </td>

        <td className="text-right">
          {row.notaCredito.toLocaleString()}
        </td>

        <td className="text-right">
          {row.facturaCompra.toLocaleString()}
        </td>

        <td className="text-right">
          {row.total.toLocaleString()}
        </td>

        <td className="text-right">
          {row.pagos.toLocaleString()}
        </td>

        <td className="text-right">
          {row.cuentaSocio ? row.cuentaSocio.toLocaleString() : ''}
        </td>

        <td className="text-right">
          {row.gastos.toLocaleString()}
        </td>

        <td className="text-right">
          {row.otros.toLocaleString()}
        </td>

        <td className="text-right">
          {row.total2.toLocaleString()}
        </td>

        <td className="text-right">
          {row.saldo.toLocaleString()}
        </td>

        <td className="text-right">
        </td>
      </tr>
    );
  }

  renderRows = () => {
    return (
      this.props.estadosDeCuentasConsolidados.map((row, index) => {
        if ((row.rowType === "DATA") && ((index === 0) || (row.proveedor.codigo !== this.props.estadosDeCuentasConsolidados[index - 1].proveedor.codigo))) {
          return (
            <>
              <tr key={index} className="text-nowrap font-weight-bold light-grey">
                <td colSpan={19}>
                  Proveedor de Fruta {row.proveedor.nombre}
                </td>
              </tr>

              {this.renderRow(row)}
            </>
          );
        } else if (row.rowType === "TOTAL_PROVEEDOR" || row.rowType === "TOTAL_GLOBAL") {
          return(
            <>
              {this.renderTotalRow(row)}
            </>
          );
        } else {
          return(
            <>
              {this.renderRow(row)}
            </>
          );
        }
      })
    );
  }

  renderTable = () => {
    if (this.props.estadosDeCuentasConsolidados.length > 0) {
      const dateFormatted = format(new Date(), "dd/MM/yyyy");
      const title = `Estado de cuenta consolidado de proveedores de fruta al ` + dateFormatted + ` - zafra ` + this.zafra 
        + `\n` + (this.vapor === 0 ? (`- Vapor: ` + this.vapor + ` - `) : ``) + (this.saldoCero === '1' ? `Incluye saldos cero` : `No incluye saldos cero`) + ` - Importes FOB - Moneda U$S`;

      return (
        <>
          <Row className="container-fluid justify-content-center handle-new-line">
            <p className="text-center text-info">{title}</p>
          </Row>

          <Row className="container-fluid justify-content-end">
            <ReactHtmlTableToExcel 
              id="btnExportEstadoDeCuentaConsolidado"
              className="btn btn-success btn-xls"
              table="table-estado-de-cuenta-consolidado"
              filename="estado-de-cuenta-consolidado"
              buttonText="Exportar XLS"
            />
          </Row>

          <Table id="table-estado-de-cuenta-consolidado" hover size="sm">
            <thead>
              <tr>
                <th>Vapor</th>
                <th>Barco</th>
                <th>Factura</th>
                <th>Mercado</th>
                <th>Cliente</th>
                <th>Tons.Netas</th>
                <th>Facturas</th>
                <th>Liq+</th>
                <th>Liq-</th>
                <th>NCred</th>
                <th>FacComp</th>
                <th>Total</th>
                <th>Pagos</th>
                <th>CtaSoc</th>
                <th>GtoCorr</th>
                <th>Otros</th>
                <th>Total</th>
                <th>Saldo</th>
                <th>FecProb</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRows()}
            </tbody>
          </Table>
        </>
      );
    } else {
      return (<></>);
    }
  }

  render() {
    return (
      <>
        <Row>
          {this.renderBreadcrumb()}
        </Row>

        <Row>
          <Waiting status={this.props.status}/>
          <BoxError status={this.props.status} error={this.props.error} />
        </Row>
        
        <Row>
          {this.renderTable()}
        </Row>
      </>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ResultEstadoDeCuentaConsolidado);