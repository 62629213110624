import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROCESSING } from '../../app-config';

const MyButton = (props) => {

  // color        = {props.color} 
  // colorSpinner = {props.colorSpinner}
  // status       = {props.status} 
  // value        = {props.value} 
  // onClick      = {props.onClick}
  // type         = {props.type}
  // icon         = {props.icon}
  // margin       = {props.margin}


  const val = props.icon 
    ? <FontAwesomeIcon icon={props.icon} />
    : props.value;

  return (
    <Button 
      color={props.color} 
      onClick={props.onClick}
      type={props.type} 
      className={props.margin}>
      
      {
        props.status === PROCESSING 
          ? <Spinner size="sm" color={props.colorSpinner} />
          : val
      }
    </Button>
  );

}

export default MyButton;