import React, { useState } from 'react';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import MyButton from '../custom/my-button.component';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input } from 'reactstrap';
import { assignRoleToUser, unassignRoleFromUser } from '../../store/actions/user.action';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    status: state.adminUser.status,
    roles: state.adminUser.roles
  };
}

const mapDispatchToProps = (dispatch) => ({
  assignRoleToUser: (userId, role) => {dispatch(assignRoleToUser(userId, role))},
  unassignRoleFromUser: (userId, role) => {dispatch(unassignRoleFromUser(userId, role))}
});

const AdminUserRoles = (props) => {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleConfirmDialog = () => {
    setModal(!modal);
  };

  const handleCheck = (event, role) => {
    if (event.target.checked) {
      props.assignRoleToUser(props.user.id, role);
    } else {
      props.unassignRoleFromUser(props.user.id, role);
    }
  };

  const userHasRole = (role) => {
    return props.user.roles.filter(r => r === role).length > 0;
  };
  
  const renderRoles = () => {
    return (
      <Table borderless>
        <tbody>
          {props.roles.map(role => {
            return (
              <tr key={role}>
                <td>{role}</td>
                <td>
                  <Input type="checkbox" id="checkbox2" onClick={(event) => handleCheck(event, role)} 
                    defaultChecked={userHasRole(role)}/>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      <MyButton 
        color="success"
        colorSpinner="light"
        icon={faShieldAlt}
        status={props.status}
        onClick={toggleConfirmDialog}
        margin="ml-2"
      />

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Roles de {props.user.username}</ModalHeader>
        <ModalBody>
          {renderRoles()}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </>
  );

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserRoles);