import { API_URL } from './../../app-config';
import axios from 'axios';
import authHeader from './../../services/auth-header';
import authService from '../../services/auth.service';
import { 
  GET_ZAFRAS_REQUESTED,
  GET_ZAFRAS_RECEIVED,
  GET_ZAFRAS_FAILED,
  GET_PROVEEDORES_REQUESTED,
  GET_PROVEEDORES_RECEIVED,
  GET_PROVEEDORES_FAILED,
  GET_CLIENTES_REQUESTED,
  GET_CLIENTES_RECEIVED,
  GET_CLIENTES_FAILED,
  GET_VARIEDADES_REQUESTED,
  GET_VARIEDADES_RECEIVED,
  GET_VARIEDADES_FAILED,
  GET_VAPORES_REQUESTED,
  GET_VAPORES_RECEIVED,
  GET_VAPORES_FAILED, 
  GET_ENVASES_REQUESTED,
  GET_ENVASES_RECEIVED,
  GET_ENVASES_FAILED,
  REPORT_ESTADO_DE_CUENTA_DETALLADO_REQUESTED,
  REPORT_ESTADO_DE_CUENTA_DETALLADO_RECEIVED,
  REPORT_ESTADO_DE_CUENTA_DETALLADO_FAILED,
  GET_PAISES_REQUESTED,
  GET_PAISES_RECEIVED,
  GET_PAISES_FAILED,
  REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_REQUESTED,
  REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_FAILED,
  REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_RECEIVED,
  REPORT_FACTURAS_RECEIVED,
  REPORT_FACTURAS_FAILED,
  REPORT_FACTURAS_REQUESTED,
  REPORT_DUAS_REQUESTED,
  REPORT_DUAS_RECEIVED,
  REPORT_DUAS_FAILED,
  REPORT_CALIBRES_REQUESTED,
  REPORT_CALIBRES_RECEIVED,
  REPORT_CALIBRES_FAILED
} from './ActionTypes';

export const getZafras = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_ZAFRAS_REQUESTED
  });

  axios.get(API_URL + '/zafras', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_ZAFRAS_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_ZAFRAS_FAILED,
        payload: error.message
      });
    }
  );
}

export const getProveedores = (reportNumber, zafra) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_PROVEEDORES_REQUESTED
  });

  axios.get(`${API_URL}/proveedores/${reportNumber}/${zafra}`, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_PROVEEDORES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_PROVEEDORES_FAILED,
        payload: error.message
      });
    }
  );
}

export const getClientes = (reportNumber, zafra) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_CLIENTES_REQUESTED
  });

  axios.get(`${API_URL}/clientes/${reportNumber}/${zafra}`, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_CLIENTES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_CLIENTES_FAILED,
        payload: error.message
      });
    }
  );
}

export const getVariedades = (reportNumber, zafra) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_VARIEDADES_REQUESTED
  });

  axios.get(`${API_URL}/variedades/${reportNumber}/${zafra}`, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_VARIEDADES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_VARIEDADES_FAILED,
        payload: error.message
      });
    }
  );
}

export const getVapores = (zafra) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_VAPORES_REQUESTED
  });

  axios.get(API_URL + '/vapores/' + zafra, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_VAPORES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_VAPORES_FAILED,
        payload: error.message
      });
    }
  );
}

export const getPaises = (reportNumber, zafra) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_PAISES_REQUESTED
  });

  axios.get(`${API_URL}/paises/${reportNumber}/${zafra}`, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_PAISES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_PAISES_FAILED,
        payload: error.message
      });
    }
  );
}

export const getEnvases = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_ENVASES_REQUESTED
  });

  axios.get(API_URL + '/envases', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_ENVASES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_ENVASES_FAILED,
        payload: error.message
      });
    }
  );
}

export const getEstadosDeCuentasDetallados = (zafra, codigoProveedor, codigoCliente, codigoVariedad, codigoVapor, fechaHasta, saldo, filter) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: REPORT_ESTADO_DE_CUENTA_DETALLADO_REQUESTED
  });

  const queryParameters = "?proveedor=" + codigoProveedor
    + "&cliente=" + codigoCliente
    + "&variedad=" + codigoVariedad
    + "&vapor=" + codigoVapor
    + "&fechaHasta=" + fechaHasta
    + "&saldo=" + saldo
    + "&filter=" + filter;

  console.log(queryParameters);
  
  axios.get(API_URL + '/reports/estadosdecuentasdetallados/' + zafra + queryParameters, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: REPORT_ESTADO_DE_CUENTA_DETALLADO_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: REPORT_ESTADO_DE_CUENTA_DETALLADO_FAILED,
        payload: error.message
      });
    }
  );
}

export const getEstadosDeCuentasConsolidados = (zafra, codigoProveedor, codigoCliente, codigoMercado, codigoVapor, saldo, soloTotales) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_REQUESTED
  });

  const queryParameters = "?proveedor=" + codigoProveedor
    + "&cliente=" + codigoCliente
    + "&mercado=" + codigoMercado
    + "&vapor=" + codigoVapor
    + "&saldo=" + saldo
    + "&soloTotales=" + soloTotales;

  console.log(queryParameters);

  axios.get(API_URL + '/reports/estadosdecuentasconsolidados/' + zafra + queryParameters, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_FAILED,
        payload: error.message
      });
    }
  );
}

export const getFacturas = (zafra, codigoProveedor, codigoCliente, codigoMercado, codigoVariedad, codigoVapor, 
  semanaDesde, semanaHasta, soloTotales, totalesEnDolares) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: REPORT_FACTURAS_REQUESTED
  });

  const queryParameters = "?proveedor=" + codigoProveedor
    + "&cliente=" + codigoCliente
    + "&mercado=" + codigoMercado
    + "&variedad=" + codigoVariedad
    + "&vapor=" + codigoVapor
    + "&semanaDesde=" + semanaDesde
    + "&semanaHasta=" + semanaHasta
    + "&soloTotales=" + soloTotales
    + "&totalesEnDolares=" + totalesEnDolares;

  console.log(queryParameters);

  axios.get(API_URL + '/reports/facturas/' + zafra + queryParameters, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: REPORT_FACTURAS_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: REPORT_FACTURAS_FAILED,
        payload: error.message
      });
    }
  );
}

export const getDuas = (zafra, codigoVapor) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: REPORT_DUAS_REQUESTED
  });

  const queryParameters = "?vapor=" + codigoVapor;

  console.log(queryParameters);

  axios.get(API_URL + '/reports/duas/' + zafra + queryParameters, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: REPORT_DUAS_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: REPORT_DUAS_FAILED,
        payload: error.message
      });
    }
  );
}

export const getCalibres = (zafra, codigoProveedor, codigoCliente, codigoVariedad, envase, codigoVapor,
  salidaDesde, salidaHasta, categoria, soloTotales) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: REPORT_CALIBRES_REQUESTED
  });

  const queryParameters = "?proveedor=" + codigoProveedor
    + "&cliente=" + codigoCliente
    + "&variedad=" + codigoVariedad
    + "&envase=" + envase
    + "&vapor=" + codigoVapor
    + "&salidaDesde=" + salidaDesde
    + "&salidaHasta=" + salidaHasta
    + "&categoria=" + categoria
    + "&soloTotales=" + soloTotales;

  console.log(queryParameters);

  axios.get(API_URL + '/reports/calibres/' + zafra + queryParameters, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: REPORT_CALIBRES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: REPORT_CALIBRES_FAILED,
        payload: error.message
      });
    }
  );
}
