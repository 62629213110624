import { 
  GET_ZAFRAS_REQUESTED, 
  GET_ZAFRAS_RECEIVED,
  GET_ZAFRAS_FAILED, 
  GET_PROVEEDORES_REQUESTED,
  GET_PROVEEDORES_FAILED,
  GET_PROVEEDORES_RECEIVED,
  GET_CLIENTES_REQUESTED,
  GET_CLIENTES_RECEIVED,
  GET_CLIENTES_FAILED,
  GET_VARIEDADES_FAILED,
  GET_VARIEDADES_RECEIVED,
  GET_VARIEDADES_REQUESTED,
  GET_VAPORES_REQUESTED,
  GET_VAPORES_RECEIVED,
  GET_VAPORES_FAILED,
  REPORT_ESTADO_DE_CUENTA_DETALLADO_REQUESTED,
  REPORT_ESTADO_DE_CUENTA_DETALLADO_RECEIVED,
  REPORT_ESTADO_DE_CUENTA_DETALLADO_FAILED, GET_PAISES_REQUESTED, GET_PAISES_RECEIVED, GET_PAISES_FAILED, REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_REQUESTED, REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_RECEIVED, REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_FAILED, REPORT_FACTURAS_REQUESTED, REPORT_FACTURAS_RECEIVED, REPORT_FACTURAS_FAILED, REPORT_DUAS_REQUESTED, REPORT_DUAS_RECEIVED, REPORT_DUAS_FAILED, REPORT_CALIBRES_REQUESTED, REPORT_CALIBRES_RECEIVED, REPORT_CALIBRES_FAILED, GET_ENVASES_REQUESTED, GET_ENVASES_RECEIVED, GET_ENVASES_FAILED
} from "../actions/ActionTypes";

import { PROCESSING, RECEIVED, FAILED } from "../../app-config";

const initialState = {
  zafras: [],
  proveedores: [],
  clientes: [],
  variedades: [],
  paises: [],
  envases: [],
  vapores: [],
  estadosDeCuentasDetallados: [],
  estadosDeCuentasConsolidados: [],
  facturas: [],
  duas: [],
  calibres: [],
  status: "",
  error: "",
};

const UrudorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ZAFRAS_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_ZAFRAS_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          zafras: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_ZAFRAS_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_PROVEEDORES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_PROVEEDORES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          proveedores: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_PROVEEDORES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_CLIENTES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_CLIENTES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          clientes: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_CLIENTES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_VARIEDADES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_VARIEDADES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          variedades: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_VARIEDADES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_PAISES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_PAISES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          paises: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_PAISES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_ENVASES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_ENVASES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          envases: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_ENVASES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;
  
    case GET_VAPORES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_VAPORES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          vapores: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_VAPORES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case REPORT_ESTADO_DE_CUENTA_DETALLADO_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case REPORT_ESTADO_DE_CUENTA_DETALLADO_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          estadosDeCuentasDetallados: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case REPORT_ESTADO_DE_CUENTA_DETALLADO_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          estadosDeCuentasConsolidados: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;
    case REPORT_FACTURAS_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case REPORT_FACTURAS_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          facturas: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case REPORT_FACTURAS_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case REPORT_DUAS_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case REPORT_DUAS_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          duas: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case REPORT_DUAS_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case REPORT_CALIBRES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case REPORT_CALIBRES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          calibres: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case REPORT_CALIBRES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    default:
      // nothing to do!
  }

  return state;
}

export default UrudorReducer;