import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Row, Table } from 'reactstrap';
import Waiting from '../custom/waiting.component';
import BoxError from '../custom/box-error.component';
import { getEstadosDeCuentasDetallados } from '../../store/actions/urudor.action';
import { format } from 'date-fns';
import { formatNumber } from '../utils/formatNumber';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

const mapStateToProps = state => {
  return {
    estadosDeCuentasDetallados: state.urudor.estadosDeCuentasDetallados,
    status: state.urudor.status,
    error: state.urudor.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEstadosDeCuentasDetallados: (zafra, codigoProveedor, codigoCliente, codigoVariedad, codigoVapor, fechaHasta, saldo, filter) => 
    {dispatch(getEstadosDeCuentasDetallados(zafra, codigoProveedor, codigoCliente, codigoVariedad, codigoVapor, fechaHasta, saldo, filter))}
});

class ResultEstadoDeCuentaDetallado extends Component {

  zafra = null;
  proveedor = null;
  cliente = null;
  variedad = null;
  vapor = null;
  fecha = null;
  saldoCero = null;
  filter = null;

  constructor(props) {
    super(props);

    this.zafra = this.props.match.params.zafra;
    this.proveedor = this.props.match.params.proveedor;
    this.cliente = this.props.match.params.cliente;
    this.variedad = this.props.match.params.variedad;
    this.vapor = this.props.match.params.vapor;
    this.fecha = this.props.match.params.fecha;
    this.saldoCero = this.props.match.params.saldocero;
    this.filter = this.props.match.params.filter;
    
    this.props.getEstadosDeCuentasDetallados(
      this.zafra, this.proveedor, this.cliente, this.variedad, this.vapor, this.fecha, this.saldoCero, this.filter);
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2 ml-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/user/report/estadodecuentadetallado">Estado de cuenta detallado</a></BreadcrumbItem>
          <BreadcrumbItem>Informe</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  renderColumns = (row) => {
    return (
      <>
        <td className="text-right">
          {row.op === 'FAC' || row.op === 'Pago' ? row.vapor.codigo : ''}
        </td>

        <td className="text-nowrap">
          {row.op === 'FAC' 
            ? row.vapor.nombreBarco 
            : row.rowType === 'TOTAL_FACTURA' ? 'Total Factura' : ''}
        </td>

        <td>
          {row.op === 'FAC' ? formatNumber(Number(row.factura), 0) : ''}
        </td>

        <td>
          {row.dua ? formatNumber(Number(row.dua), 0) : ''}
        </td>

        <td className="text-center">
          {row.op === 'FAC' || row.rowType === 'TOTAL_FACTURA' ? formatNumber(row.cajas, 0) : ''}
        </td>

        <td>
          {row.op === 'FAC' || row.rowType === 'TOTAL_FACTURA' ? '(' + row.envase + ')': ''}
        </td>

        <td>
          {row.fecha ? format(Date.parse(row.fecha + 'T00:00:00'), "dd/MM/yyyy") : ''}
        </td>

        <td className="text-nowrap">
          {row.op}
        </td>

        <td className="text-center">
          {row.moneda}
        </td>

        <td className="text-right">
          {row.debe === 0 ? formatNumber(0) : formatNumber(row.debe)}
        </td>
        
        <td className="text-right">
          {row.haber === 0 ? formatNumber(0) : formatNumber(row.haber)}
        </td>

        <td className="text-right">
          {!row.saldo ? formatNumber(0) : formatNumber(row.saldo)}
        </td>

        <td>
          {row.banco}
        </td>

        <td>
          {row.referencia}
        </td>
      </>
    );
  }

  renderClientName = (row) => {
    if (row.rowType === 'TOTAL_CLIENTE') {
      return 'Total ' + row.cliente.nombre;
    } else if (row.rowType === 'TOTAL_PROVEEDOR') {
      return 'Total ' + row.proveedor.nombre;
    } else {
      return '';
    }
  }

  renderRows = (rows) => {
    return (
      rows.map((row, index) => {
        if ((row.rowType === "DATA") && ((index === 0) || (row.cliente.nombre !== rows[index - 1].cliente.nombre))) {
          return (
            <tr className="text-nowrap" key={index}>
              <th>{row.cliente.nombre}</th>
              {this.renderColumns(row)}
            </tr>
          );
        } else if (row.rowType === "TOTAL_CLIENTE") {
          return(
            <tr key={index} className="text-nowrap font-weight-bold ligh-grey">
              <td>
                {this.renderClientName(row)}
              </td>

              {this.renderColumns(row)}
            </tr>
          );
        } else if (row.rowType === "TOTAL_PROVEEDOR") {
          return(
            <tr key={index} className="text-nowrap font-weight-bold normal-grey">
              <td>
                {this.renderClientName(row)}
              </td>

              {this.renderColumns(row)}
            </tr>
          );
        } else {
          return(
            <tr key={index} className={row.rowType.startsWith("TOTAL_") ? "font-weight-bold" : ""}>
              <td>
                {this.renderClientName(row)}
              </td>

              {this.renderColumns(row)}
            </tr>
          );
        }
      })
    );
  }

  renderTable = (rows) => {
    return (
      <Table id="table-estado-de-cuenta-detallado" hover size="sm">
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Vapor</th>
            <th>Barco</th>
            <th>Factura</th>
            <th>DUA</th>
            <th>Cajas</th>
            <th></th>
            <th>Fecha</th>
            <th>Op</th>
            <th>Moneda</th>
            <th>Debe</th>
            <th>Haber</th>
            <th>Saldo</th>
            <th>Banco</th>
            <th>Referencia</th>
          </tr>
        </thead>
        <tbody>
          {this.renderRows(rows)}
        </tbody>
      </Table>
    );
  }

  renderTables = () => {
    const dateFormatted = this.fecha.replace("-", "/");

    return this.props.estadosDeCuentasDetallados.map((row, index) => {
      if ((index === 0) || (row.proveedor.nombre !== this.props.estadosDeCuentasDetallados[index - 1].proveedor.nombre)) {
        const title = `Estado de cuenta detallado de ` + row.proveedor.nombre + ` al ` + dateFormatted + ` - Zafra ` + this.zafra 
          + `\nImportes FOB - ` + (this.vapor === 0 ? (`- Vapor: ` + this.vapor + ` - `) : ``) + (this.saldoCero === '1' ? `Incluye saldos cero` : `No incluye saldos cero`);

        return (
          <>
            <Row className="container-fluid justify-content-center handle-new-line">
              <p className="text-center text-info">{title}</p>
            </Row>

            <Row className="container-fluid justify-content-end">
              <ReactHtmlTableToExcel 
                id="btnExportEstadoDeCuentaDetallado"
                className="btn btn-success btn-xls"
                table="table-estado-de-cuenta-detallado"
                filename="estado-de-cuenta-detallado"
                buttonText="Exportar XLS"
              />
            </Row>

            {this.renderTable(this.props.estadosDeCuentasDetallados.filter(r => r.proveedor.codigo === row.proveedor.codigo))}
          </>
        );
      } else {
        return (<></>);
      }
    });
  }

  render() {
    return (
      <>
        <Row>
          {this.renderBreadcrumb()}
        </Row>

        <Row>
          <Waiting status={this.props.status}/>
          <BoxError status={this.props.status} error={this.props.error} />
        </Row>
        
        <Row>
          {this.renderTables()}
        </Row>
      </>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ResultEstadoDeCuentaDetallado);