import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { PROCESSING } from '../../app-config';

const Waiting = (props) => {

  if (props.status === PROCESSING) {
    return (
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <Spinner  />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (<></>);
  }
}

export default Waiting;