import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Row, Table } from 'reactstrap';
import { getCalibres } from '../../store/actions/urudor.action';
import { format } from 'date-fns';
import Waiting from '../custom/waiting.component';
import BoxError from '../custom/box-error.component';
import { formatNumber } from '../utils/formatNumber';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

const mapStateToProps = state => {
  return {
    calibres: state.urudor.calibres,
    status: state.urudor.status,
    error: state.urudor.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCalibres: (zafra, proveedor, cliente, variedad, envase, vapor, salidaDesde, salidaHasta, categoria, soloTotales) => 
    {dispatch(getCalibres(zafra, proveedor, cliente, variedad, envase, vapor, salidaDesde, salidaHasta, categoria, soloTotales))}
});

class ResultCalibre extends Component {

  zafra = null;
  proveedor = null;
  cliente = null;
  variedad = null;
  envase = null;
  vapor = null;
  salidaDesde = null;
  salidaHasta = null;
  categoria = null;
  soloTotales = null;

  constructor(props) {
    super(props);

    this.zafra = this.props.match.params.zafra;
    this.proveedor = this.props.match.params.proveedor;
    this.cliente = this.props.match.params.cliente;
    this.variedad = this.props.match.params.variedad;
    this.envase = this.props.match.params.envase;
    this.vapor = this.props.match.params.vapor;
    this.salidaDesde = this.props.match.params.salidaDesde;
    this.salidaHasta = this.props.match.params.salidaHasta;
    this.categoria = this.props.match.params.categoria;
    this.soloTotales = this.props.match.params.soloTotales;

    this.props.getCalibres(this.zafra, this.proveedor, this.cliente, this.variedad, this.envase, 
      this.vapor, null, null, this.categoria, this.soloTotales);
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2 ml-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/user/report/calibre">Calibres</a></BreadcrumbItem>
          <BreadcrumbItem>Informe</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  parseDate = stringDate => format(Date.parse(stringDate + 'T00:00:00'), "dd/MM/yyyy");

  renderRow = (row) => {
    return (
      <tr className="text-nowrap">

        <td>
          {row.vapor.codigo}
        </td>

        <td>
          {row.vapor.nombreBarco}
        </td>

        <td>
          {row.proveedor.nombre}
        </td>

        <td className="text-right">
          {formatNumber(Number(row.numeroDocumento), 0)}
        </td>

        <td className="text-right">
          {this.parseDate(row.fechaSalida)}
        </td>

        <td className="text-left">
          {row.destino}
        </td>

        <td>
          {row.cliente.nombre}
        </td>

        <td>
          {row.variedad.nombre}
        </td>

        <td>
          {row.envase.nombre}
        </td>

        <td>
          {row.contenedor}
        </td>

        <td className="text-center">
          {row.categoria}
        </td>

        {this.renderCalCells(row)}

        <td className="text-right">
          {formatNumber(row.total)}
        </td>
        
      </tr>
    );
  }

  renderCalCells = (row) => {
    return row.availableCals.map(cal => {
      return <td className="text-right">{row.cals[cal] ? formatNumber(row.cals[cal]) : ''}</td>
    })
  }

  renderTotalRow = (row) => {
    return (
      <tr className="text-nowrap font-weight-bold normal-grey">
        <td colSpan={10}>
          Total General vapor {row.vapor.codigo} - {row.vapor.nombreBarco}
        </td>

        <td className="text-center">{row.categoria}</td>

        {this.renderCalCells(row)}

        <td className="text-right">
          {formatNumber(row.total)}
        </td>
      </tr>
    );
  }

  renderRows = () => {
    return (
      this.props.calibres.map((row, index) => {
        if (row.rowType === "TOTAL_VAPOR") {
          return(
            <>
              {this.renderTotalRow(row)}
            </>
          );
        } else {
          return(
            <>
              {this.renderRow(row)}
            </>
          );
        }
      })
    );
  }

  renderCalColumnHeaders = () => {
    if (this.props.calibres.length > 0) {
      return this.props.calibres[0].availableCals.map(cal => {
        return <th>Cal {cal}</th>  
      });
    }
  }

  renderTable = () => {

    if (this.props.calibres.length > 0) {
      const title = `Listado de Calibres - Zafra ` + this.zafra 
        + `\n` + (this.vapor !== 0 ? (`Vapor: ` + this.vapor) : ``);

      return (
        <>
          <Row className="container-fluid justify-content-center handle-new-line">
            <p className="text-center text-info">{title}</p>
          </Row>

          <Row className="container-fluid justify-content-end">
            <ReactHtmlTableToExcel 
              id="btnExportCalibre"
              className="btn btn-success btn-xls"
              table="table-calibre"
              filename="calibre"
              buttonText="Exportar XLS"
            />
          </Row>

          <Table id="table-calibre" hover size="sm">
            <thead>
              <tr className="text-nowrap">
                <th>Vapor</th>
                <th>Barco</th>
                <th>Prov. Fruta</th>
                <th># Factura</th>
                <th>Salida</th>
                <th>Destino</th>
                <th>Cliente</th>
                <th>Variedad</th>
                <th>Envase</th>
                <th>Contenedor</th>
                <th>Categoria</th>
                {this.renderCalColumnHeaders()}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRows()}
            </tbody>
          </Table>
        </>
      );
    } else {
      return (<></>);
    }
  }

  render() {
    return (
      <>
        <Row>
          {this.renderBreadcrumb()}
        </Row>

        <Row>
          <Waiting status={this.props.status}/>
          <BoxError status={this.props.status} error={this.props.error} />
        </Row>
        
        <Row>
          {this.renderTable()}
        </Row>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultCalibre);