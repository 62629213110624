import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    status: state.urudor.status,
    vapores: state.urudor.vapores,
  }
};

class SelectVapores extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    }
  }

  toggle = () => this.setState({modal: !this.state.modal});
  
  toggleConfirmDialog = () => this.setState({modal: !this.state.modal});

  selectVapor = (vapor) => {
    this.props.onChange(vapor);
    this.toggle();
  } 

  render() {
    return (
      <>
  
        <Button color="primary" onClick={this.toggleConfirmDialog}>
          <FontAwesomeIcon icon={faListOl} /> 
        </Button>
  
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
          <ModalBody>
  
            <Table hover borderless size="sm">
              <thead>
                <tr>
                  <th>Vapor</th>
                  <th>Barco</th>
                  <th>Fecha de Salida</th>
                </tr>
              </thead>
              <tbody>
                {this.props.vapores.map(vapor => {
                  return (
                    <tr key={vapor.codigo} onClick={() => this.selectVapor(vapor)}>
                      <td>{vapor.codigo}</td>
                      <td>{vapor.nombreBarco}</td>
                      <td>{vapor.fechaSalida}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

}

export default connect(mapStateToProps)(SelectVapores);