import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import BoardLeftMenu from './board-left-menu.component';

class CondicionesTecnincas extends Component {

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem>Condiciones Técnicas</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  render() {
    return (
      <>
      <Row className="iframe">
        <Col md="3" >
          <BoardLeftMenu />
        </Col>
        <Col md="9" >
            <iframe title="marianne" src="/ct/catalogocli.html" className="iframe"></iframe>
        </Col>
      </Row>
    </>
    );
  }

}

export default CondicionesTecnincas;