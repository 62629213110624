import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Label, Col, Button } from 'reactstrap';
import { createUser, getUserById, updateUser } from '../../store/actions/user.action';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import BoxSuccess from '../custom/box-success.component';
import BoxError from '../custom/box-error.component';
import MyButton from '../custom/my-button.component';
import Waiting from '../custom/waiting.component';

const mapStateToProps = state => {
  return {
    status: state.adminUser.status,
    error: state.adminUser.error,
    message: state.adminUser.message,
    user: state.adminUser.user
  };
}

const mapDispatchToProps = (dispatch) => ({
  createUser: (username, email, password) => {dispatch(createUser(username, email, password))},
  updateUser: (userId, username, email) => {dispatch(updateUser(userId, username, email))},
  getUserById: (userId) => {dispatch(getUserById(userId))},
});

class AdminUser extends Component {

  initialValues = {};

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    if (this.props.match.params.userId) {
      this.props.getUserById(this.props.match.params.userId);
    }

    this.initializeValues();
  }

  initializeValues() {
    this.initialValues =  {
      username: "",
      email: "",
      password: "",
      confirmPassword: ""
    }
  }

  handleSubmit(values) {
    if (this.props.user.id) {
      this.props.updateUser(this.props.user.id, values.username, values.email);
    } else {
      this.props.createUser(values.username, values.email, values.password);
    }
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/admin">Admin</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/admin/users">Usuarios</a></BreadcrumbItem>
          <BreadcrumbItem active>Nuevo</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  renderPasswordControls() {
    if (!this.props.user.id) {
      return (
        <Row className="form-group">
          <Col md={6}>
            <Label htmlFor="password">Clave</Label>
            <Field type="password" name="password" className="form-control" />
            <ErrorMessage name="password" component="div" className="text-danger" />
          </Col>

          <Col md={6}>
            <Label htmlFor="confirmPassword">Confirmar Clave</Label>
            <Field type="password" name="confirmPassword" className="form-control" />
            <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
          </Col>
        </Row>
      );
    }
  }

  renderForm() {

    const validateForm = values => {
      const errors = {};

      if (!values.username) {
        errors.username = "Nombre de usuario vacio";
      } else if (values.username.length < 4){
        errors.username = "Tiene que tener mas de 4 caracteres";
      } else if (values.username.length > 50){
        errors.username = "Tiene que tener menos de 50 caracteres";
      }

      if (!values.email) {
        errors.email = "Correo Electrónico vacio";
      } else if (! /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Correo Electrónico no válido";
      }

      if (!this.props.user.id) {
        if (!values.password) {
          errors.password = "Clave vacía";
        } else if (values.password.length < 6) {
          errors.password = "Tiene que tener mas de 4 caracteres";
        } else if (values.password.length > 20) {
          errors.password = "Tienen que tener menos de 20 caracateres";
        }
      }

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Las claves no coinciden"
      }

      return errors;
    }

    if (this.props.user && this.props.user.id > 0) {
      this.initialValues = {
        username: this.props.user.username,
        email: this.props.user.email,
        password: "",
        confirmPassword: ""
      };
    }

    return (
      <>
        <Formik 
          enableReinitialize={true} 
          initialValues={this.initialValues}
          validate={validateForm}
          onSubmit={
            (values) => {
              this.handleSubmit(values);
            }
          }
        >
          <Form>
            <Row className="form-group">
              <Col md={6}>
                <Label htmlFor="username">Nombre de usuario</Label>
                <Field type="text" name="username" className="form-control" />
                <ErrorMessage name="username" component="div" className="text-danger" />
              </Col>

              <Col md={6}>
                <Label htmlFor="email">Correo Electrónico</Label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage name="email" component="div" className="text-danger" />
              </Col>
            </Row>

            {this.renderPasswordControls()}

            <Row className="form-group">
              <Col className="d-flex justify-content-end">
                <MyButton 
                  type="submit"
                  color="primary"
                  colorSpinner="light"
                  value="Guardar"
                  status={this.props.status}
                  />

                <Button type="reset" className="secondary ml-2">
                  Limpiar
                </Button>
              </Col>
            </Row>
          </Form>

        </Formik>
      </>
    );
  }

  render() {
    return(
      <>
        {this.renderBreadcrumb()}
        <Waiting status={this.props.status}/>
        <BoxError status={this.props.status} error={this.props.error} />
        <BoxSuccess status={this.props.status} message={this.props.message} />
        
        {this.renderForm()}
      </>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);