import { 
  GET_USERS_REQUESTED, 
  GET_USERS_RECEIVED, 
  GET_USERS_FAILED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_RECEIVED,
  UPDATE_USER_FAILED,
  DELETE_USER_REQUESTED,
  DELETE_USER_RECEIVED,
  DELETE_USER_FAILED,
  GET_USER_REQUESTED,
  GET_USER_RECEIVED,
  GET_USER_FAILED,
  UPDATE_PASSWORD_REQUESTED,
  UPDATE_PASSWORD_RECEIVED,
  UPDATE_PASSWORD_FAILED,
  ASSIGN_ROLE_FAILED,
  ASSIGN_ROLE_RECEIVED,
  ASSIGN_ROLE_REQUESTED,
  GET_ROLES_FAILED,
  GET_ROLES_RECEIVED,
  GET_ROLES_REQUESTED,
  UNASSIGN_ROLE_REQUESTED,
  UNASSIGN_ROLE_RECEIVED,
  UNASSIGN_ROLE_FAILED
} from './ActionTypes';
import { API_URL } from './../../app-config';
import axios from 'axios';
import authHeader from './../../services/auth-header';
import authService from '../../services/auth.service';

export const getUserById = (userId) => (dispatch) => {
  authService.validateToken();
  dispatch({
    type: GET_USER_REQUESTED
  });

  axios.get(API_URL + '/users/' + userId, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_USER_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_USER_FAILED,
        payload: error.message
      });
    }
  );
}

export const getUsers = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_USERS_REQUESTED
  });

  axios.get(API_URL + '/users', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_USERS_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_USERS_FAILED,
        payload: error.message
      });
    }
  );
}

export const createUser = (username, email, password) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: UPDATE_USER_REQUESTED
  });

  let newUser = {
    username,
    email,
    password
  };

  axios.post(API_URL + "/users", newUser, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: UPDATE_USER_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: UPDATE_USER_FAILED,
        payload: error.response.data
      });
    }
  );
}

export const updateUser = (userId, username, email) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: UPDATE_USER_REQUESTED
  });

  let user = {
    id: userId,
    username: username,
    email: email
  };

  axios.put(API_URL + "/users", user, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: UPDATE_USER_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: UPDATE_USER_FAILED,
        payload: error.response.status === 500 
          ? error.response.data.message 
          : error.response.data
      });
    }
  );
}

export const deleteUser = (userId) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: DELETE_USER_REQUESTED
  });

  axios.delete(API_URL + "/users/" + userId, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: DELETE_USER_RECEIVED,
        payload: userId
      });
    },
    error => {
      dispatch({
        type: DELETE_USER_FAILED,
        payload: error.response.data
      });
    }
  );
}

export const updateUserPassword = (userId, newPassword) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: UPDATE_PASSWORD_REQUESTED
  });

  let passwordJson = {
    password: newPassword
  }

  axios.put(API_URL + "/users/" + userId + "/password", passwordJson, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: UPDATE_PASSWORD_RECEIVED,
        payload: userId
      });
    },
    error => {
      dispatch({
        type: UPDATE_PASSWORD_FAILED,
        payload: error.response.data
      });
    }
  );
}

export const getRoles = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_ROLES_REQUESTED
  });

  axios.get(API_URL + '/roles', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_ROLES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_ROLES_FAILED,
        payload: error.message
      });
    }
  );
}

export const assignRoleToUser = (userId, role) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: ASSIGN_ROLE_REQUESTED
  });

  axios.post(API_URL + "/users/" + userId + "/roles/" + role, {}, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: ASSIGN_ROLE_RECEIVED,
        payload: {
          userId: userId,
          role: role
        }
      });
    },
    error => {
      console.log(error);
      dispatch({
        type: ASSIGN_ROLE_FAILED,
        payload: error.message
      });
    }
  );
}

export const unassignRoleFromUser = (userId, role) => (dispatch) => { 
  authService.validateToken();

  dispatch({
    type: UNASSIGN_ROLE_REQUESTED
  });

  axios.delete(API_URL + "/users/" + userId + "/roles/" + role, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: UNASSIGN_ROLE_RECEIVED,
        payload: {
          userId: userId,
          role: role
        }
      });
    },
    error => {
      dispatch({
        type: UNASSIGN_ROLE_FAILED,
        payload: error.message
      });
    }
  );
}