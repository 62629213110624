import React from "react";

import { Container } from "reactstrap";
import { Switch } from "react-router-dom";
import PrivateRoute from "../custom/private-route.component";
import ReportEstadoDeCuentaDetallado from "./report-estado-de-cuenta-detallado.component";
import ResultEstadoDeCuentaDetallado from "./result-estado-de-cuenta-detallado.component";
import ReportEstadoDeCuentaConsolidado from "./report-estado-de-cuenta-consolidado.component";
import ResultEstadoDeCuentaConsolidado from "./result-estado-de-cuenta-consolidado.component";

import UserHome from "./user-home.component";
import ReportFactura from "./report-factura.component";
import ResultFactura from "./result-factura.component";
import ReportDua from "./report-dua.component";
import ResultDua from "./result-dua.component";
import ReportCalibre from "./report-calibre.component";
import resultCalibre from "./result-calibre.component";
import CondicionesTecnincas from "./condiciones-tecnicas.component";

const BoardUser = () => {

  return (
    <Container fluid={true}>
      <Switch>
        <PrivateRoute path="/home" component={UserHome}></PrivateRoute>

        <PrivateRoute exact path="/user/report/estadodecuentadetallado" component={ReportEstadoDeCuentaDetallado} />
        <PrivateRoute exact 
          path="/user/report/estadodecuentadetallado/:zafra/:proveedor/:cliente/:variedad/:vapor/:fecha/:saldocero/:filter"
          component={ResultEstadoDeCuentaDetallado} />

        <PrivateRoute exact path="/user/report/estadodecuentaconsolidado" component={ReportEstadoDeCuentaConsolidado} />
        <PrivateRoute exact 
          path="/user/report/estadodecuentaconsolidado/:zafra/:proveedor/:cliente/:mercado/:vapor/:saldocero/:soloTotales"
          component={ResultEstadoDeCuentaConsolidado} />

        <PrivateRoute exact path="/user/report/factura" component={ReportFactura} />
        <PrivateRoute exact path="/user/report/factura/:zafra/:proveedor/:cliente/:mercado/:variedad/:vapor/:semanaDesde/:semanaHasta/:soloTotales/:totalesEnDolares"
          component={ResultFactura} />

        <PrivateRoute exact path="/user/report/dua" component={ReportDua} />
        <PrivateRoute exact path="/user/report/dua/:zafra/:vapor" component={ResultDua} />

        <PrivateRoute exact path="/user/report/calibre" component={ReportCalibre} />
        <PrivateRoute exact path="/user/report/calibre/:zafra/:proveedor/:cliente/:variedad/:envase/:vapor/:categoria/:soloTotales" component={resultCalibre} />

        <PrivateRoute exact path="/user/report/condicionestecnicas" component={CondicionesTecnincas} />

      </Switch>
    </Container>
    );
}

export default BoardUser;