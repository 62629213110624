import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/user/board-user.component";
import BoardAdmin from "./components/admin/board-admin.component";
import Header from "./components/header.component";
import PrivateRoute from "./components/custom/private-route.component";
import { Provider } from "react-redux";
import store from './store';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Header />

            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <PrivateRoute path="/home" component={BoardUser} />
              <PrivateRoute path="/user" component={BoardUser} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/admin" component={BoardAdmin} />
              <Redirect to="/home" />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
