export const API_URL = "http://urudor.baldisser.com:9091/api";
//export const API_URL = "http://localhost:9091/api";

export const PROCESSING = "PROCESSING";
export const FAILED = "FAILED";
export const RECEIVED = "RECEIVED";

export const REPORT = {
  ESTADOS_DE_CUENTAS_DETALLADOS: 1,
  ESTADOS_DE_CUENTAS_CONSOLIDADOS: 2,
  FACTURAS: 3,
  DUAS: 5,
  CALIBRES: 4,
};

