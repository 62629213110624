import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSettings, updateSetting } from '../../store/actions/settings.action';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col, Table, Input } from 'reactstrap';
import Waiting from '../custom/waiting.component';
import BoxError from '../custom/box-error.component';
import BoxSuccess from '../custom/box-success.component';
import { RECEIVED } from '../../app-config';

const mapStateToProps = state => {
  return {
    settings: state.settings.settings,
    status: state.settings.status,
    error: state.settings.error,
    message: state.settings.message
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSettings: () => {dispatch(getSettings())},
  updateSetting: (name, value) => {dispatch(updateSetting(name, value))}
});

class AdminSettings extends Component {

  componentDidMount() {
    this.props.getSettings();
  }

  handleCheck = (event, setting) => {
    this.props.updateSetting(setting.name, event.target.checked);
  }

  renderCheckBox = (setting) => {
    if (setting.value === true) {
      return (
        <Input type="checkbox" id="checkbox2" onClick={(event) => this.handleCheck(event, setting)} defaultChecked/>
      );
    } else {
      return (
        <Input type="checkbox" id="checkbox2" onClick={(event) => this.handleCheck(event, setting)} />
      );
    }
  }

  renderSettingRow = () => {
    if (this.props.settings) {
      return this.props.settings.map(setting => 
        <tr key={setting.id}>
          <th scope="row">{setting.id}</th>
          <td>{setting.name}</td>
          <td className="d-flex justify-content-center">
            { this.renderCheckBox(setting)}
          </td>
        </tr>
      );
    }
  }

  renderSettingsTable = () => {
    if (this.props.status === RECEIVED) {
      return (
        <Table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            {this.renderSettingRow()}
          </tbody>
        </Table>
      );
    }
  }

  renderBreadcrumb = () => {
    return (
      <div>
        <Breadcrumb className="mt-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/admin">Admin</a></BreadcrumbItem>
          <BreadcrumbItem active>Configuraciones</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  };

  render() {
    return (
      <>
        { this.renderBreadcrumb() }

        <Waiting status={this.props.status}/>
        <BoxError status={this.props.status} error={this.props.error} />
        <BoxSuccess status={this.props.status} message={this.props.message}/>

        <Container>
          <Row className="mb-2">
            <Col className="d-flex justify-content-start">
              <h3>Configuracion del sistema</h3>
            </Col>
          </Row>
          <Row>
            { this.renderSettingsTable() }
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);