import { 
  GET_TABLES_REQUESTED, 
  GET_TABLES_RECEIVED,
  GET_TABLES_FAILED, 
  GET_LOGS_REQUESTED,
  GET_LOGS_RECEIVED,
  GET_LOGS_FAILED,
  IMPORT_TABLE_REQUESTED,
  IMPORT_TABLE_RECEIVED,
  IMPORT_TABLE_FAILED,
  GET_LAST_SYNC_REQUESTED,
  GET_LAST_SYNC_RECEIVED,
  GET_LAST_SYNC_FAILED} from "../actions/ActionTypes";
import { PROCESSING, RECEIVED, FAILED } from "../../app-config";

const initialState = {
  mdbTables: []
};

const ImportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TABLES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_TABLES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          mdbTables: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_TABLES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_LOGS_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_LOGS_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          logs: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_LOGS_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_LAST_SYNC_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_LAST_SYNC_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          lastSync: action.payload,
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_LAST_SYNC_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case IMPORT_TABLE_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case IMPORT_TABLE_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case IMPORT_TABLE_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;
  

    default:
      // nothing to do!
  }

  return state;
}

export default ImportsReducer;