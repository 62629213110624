import axios from "axios";
import { API_URL } from "../app-config";
import authHeader from "./auth-header";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/auth/signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "/auth/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  validateToken() {
    axios.get(API_URL + '/auth/check', { headers: authHeader() })
    .then(
      response => { },
      error => {
        if (error) {
          this.logout();
          window.location = "/login";
        }
      }
    );
  }
}

export default new AuthService();
