import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Row, Table } from 'reactstrap';
import { getFacturas } from '../../store/actions/urudor.action';
import { format } from 'date-fns';
import Waiting from '../custom/waiting.component';
import BoxError from '../custom/box-error.component';
import { formatNumber } from '../utils/formatNumber';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

const mapStateToProps = state => {
  return {
    facturas: state.urudor.facturas,
    status: state.urudor.status,
    error: state.urudor.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFacturas: (zafra, codigoProveedor, codigoCliente, codigoMercado, codigoVariedad, 
    codigoVapor, semanaDesde, semanaHasta, monedaTotales, soloTotales) => 
    {dispatch(getFacturas(zafra, codigoProveedor, codigoCliente, codigoMercado, codigoVariedad, 
      codigoVapor, semanaDesde, semanaHasta, monedaTotales, soloTotales))}
});

class ResultFactura extends Component {

  zafra = null;
  proveedor = null;
  cliente = null;
  mercado = null;
  variedad = null;
  vapor = null;
  semanaDesde = null;
  semanaHasta = null;
  soloTotales = null;
  totalesEnDolares = null;

  constructor(props) {
    super(props);

    this.zafra = this.props.match.params.zafra;
    this.proveedor = this.props.match.params.proveedor;
    this.cliente = this.props.match.params.cliente;
    this.mercado = this.props.match.params.mercado;
    this.variedad = this.props.match.params.variedad;
    this.vapor = this.props.match.params.vapor;
    this.semanaDesde = this.props.match.params.semanaDesde;
    this.semanaHasta = this.props.match.params.semanaHasta;
    this.soloTotales = this.props.match.params.soloTotales;
    this.totalesEnDolares = this.props.match.params.totalesEnDolares;

    this.props.getFacturas(
      this.zafra, this.proveedor, this.cliente, this.mercado, this.variedad, this.vapor, this.semanaDesde, 
      this.semanaHasta, this.soloTotales, this.totalesEnDolares);
  }

  renderBreadcrumb() {
    return (
      <div>
        <Breadcrumb className="mt-2 ml-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/user/report/factura">Facturas</a></BreadcrumbItem>
          <BreadcrumbItem>Informe</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }

  parseDate = stringDate => format(Date.parse(stringDate + 'T00:00:00'), "dd/MM/yyyy");

  renderRow = (row) => {
    return (
      <tr className="text-nowrap">
        <td>
          {row.proveedor.nombre}
        </td>

        <td className="text-right">
          {formatNumber(Number(row.numeroDocumento), 0)}
        </td>

        <td className="text-right">
          {isNaN(row.dua) ? row.dua : formatNumber(Number(row.dua), 0)}
        </td>

        <td>
          {row.vapor.codigo}
        </td>

        <td>
          {row.vapor.nombreBarco}
        </td>

        <td className="text-right">
          {this.parseDate(row.fechaSalida)}
        </td>

        <td className="text-right">
          {row.origen}
        </td>

        <td className="text-right">
          {row.destino}
        </td>

        <td className="text-right">
          {this.parseDate(row.fechaLlegada)}
        </td>

        <td>
          {row.cliente.nombre}
        </td>

        <td>
          {row.variedad.nombre}
        </td>

        <td>
          {row.moneda}
        </td>

        <td className="text-right">
          {formatNumber(row.importeFob)}
        </td>

        <td className="text-right">
          {formatNumber(row.kgsBrutos)}
        </td>

        <td className="text-right">
          {formatNumber(row.kgsNetos)}
        </td>

        <td className="text-right">
          {row.pallets.toLocaleString()}
        </td>

        <td>
          {row.envase}
        </td>

        <td className="text-right">
          {formatNumber(row.cajas1)}
        </td>

        <td className="text-right">
          {formatNumber(row.cajas2)}
        </td>

        <td className="text-right">
          {formatNumber(row.cajas3)}
        </td>

        <td className="text-right">
          {formatNumber(row.bin1)}
        </td>

        <td className="text-right">
          {formatNumber(row.bin2)}
        </td>

        <td className="text-right">
          {formatNumber(row.bin3)}
        </td>

        <td className="text-right">
          {formatNumber(row.total)}
        </td>
        
      </tr>
    );
  }

  renderTotalRow = (row) => {
    return (
      <tr className="text-nowrap font-weight-bold normal-grey">
        <td colSpan={11}>
          Total {row.rowType === 'TOTAL_PROVEEDOR' ? row.proveedor.nombre : 'GENERAL'}
        </td>

        <td className="text-right">
          {row.moneda}
        </td>

        <td className="text-right">
          {this.totalesEnDolares ? formatNumber(row.importeEnDolaresFob) : formatNumber(row.importeFob)}
        </td>

        <td className="text-right">
          {formatNumber(row.kgsBrutos)}
        </td>

        <td className="text-right">
          {formatNumber(row.kgsNetos)}
        </td>

        <td className="text-right">
          {row.pallets.toLocaleString()}
        </td>

        <td className="text-right">

        </td>

        <td className="text-right">
          {formatNumber(row.cajas1)}
        </td>

        <td className="text-right">
          {formatNumber(row.cajas2)}
        </td>

        <td className="text-right">
          {formatNumber(row.cajas3)}
        </td>

        <td className="text-right">
          {formatNumber(row.bin1)}
        </td>

        <td className="text-right">
          {formatNumber(row.bin2)}
        </td>

        <td className="text-right">
          {formatNumber(row.bin3)}
        </td>

        <td className="text-right">
          {formatNumber(row.total)}
        </td>
      </tr>
    );
  }

  renderRows = () => {
    return (
      this.props.facturas.map((row, index) => {
        if (row.rowType === "TOTAL_PROVEEDOR" || row.rowType === "TOTAL_GLOBAL") {
          return(
            <>
              {this.renderTotalRow(row)}
            </>
          );
        } else {
          return(
            <>
              {this.renderRow(row)}
            </>
          );
        }
      })
    );
  }

  renderTable = () => {
    if (this.props.facturas.length > 0) {
      const title = `Listado de Facturas (con documentos complementarios) de Proveedores de Fruta - Zafra ` + this.zafra 
        + `\n` + (this.vapor !== 0 ? (`Vapor: ` + this.vapor + ` - `) : ``) + (this.monedaTotales === 0 ? `Totales en moneda de origen` : `Totales en Dólares`);

      return (
        <>
          <Row className="container-fluid justify-content-center handle-new-line">
            <p className="text-center text-info">{title}</p>
          </Row>

          <Row className="container-fluid justify-content-end">
            <ReactHtmlTableToExcel 
              id="btnExportFactura"
              className="btn btn-success btn-xls"
              table="table-factura"
              filename="factura"
              buttonText="Exportar XLS"
            />
          </Row>

          <Table id="table-factura" hover size="sm">
            <thead>
              <tr className="text-nowrap">
                <th>Prov. Fruta</th>
                <th># Factura</th>
                <th>DUA</th>
                <th>Vapor</th>
                <th>Barco</th>
                <th>Salida</th>
                <th>Origen</th>
                <th>Destino</th>
                <th>Llegada</th>
                <th>Cliente</th>
                <th>Variedad</th>
                <th>Moneda</th>
                <th>Importe FOB</th>
                <th>K.Brutos</th>
                <th>K.Netos</th>
                <th>Pallets</th>
                <th>Envases</th>
                <th>Cajas1ª</th>
                <th>Cajas2ª</th>
                <th>Cajas3ª</th>
                <th>Bin1ª</th>
                <th>Bin2ª</th>
                <th>Bin3ª</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRows()}
            </tbody>
          </Table>
        </>
      );
    } else {
      return (<></>);
    }
  }

  render() {
    return (
      <>
        <Row>
          {this.renderBreadcrumb()}
        </Row>

        <Row>
          <Waiting status={this.props.status}/>
          <BoxError status={this.props.status} error={this.props.error} />
        </Row>
        
        <Row>
          {this.renderTable()}
        </Row>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultFactura);