import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { deleteUser } from '../../store/actions/user.action';
import { connect } from 'react-redux';
import MyButton from '../custom/my-button.component';

const mapStateToProps = state => {
  return {
    status: state.adminUser.status
  }
};

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (userId) => {dispatch(deleteUser(userId))}
});

const AdminDeleteUser = (props) => {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  
  const onConfirm = () => {
    props.deleteUser(props.userId);
    toggle();
  }

  const toggleConfirmDialog = () => setModal(!modal);

  return (
    <>
      <MyButton 
        color="danger"
        colorSpinner="light"
        icon={faTrashAlt}
        status={props.status}
        onClick={toggleConfirmDialog}
        margin="ml-2"
        />

      <Modal isOpen={modal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody>
          Está seguro?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onConfirm}>Confirmar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </>
  );

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDeleteUser);