import React from 'react';
import { NavLink, Nav, NavItem } from "reactstrap";
import authService from "../../services/auth.service";

const BoardLeftMenu = () => {

  const currentUser = authService.getCurrentUser();

  const menuItemEstadoDeCuentaDetallado = () => {
    let isEnabled = currentUser.roles.includes("ROLE_ADMIN") === true 
      || (currentUser.consultasExportacionesEnabled === true && currentUser.consultasExportacionesEconomicosEnabled === true)
    
    if (isEnabled) {
      return (
        <NavItem>
          <NavLink href="/user/report/estadodecuentadetallado">Estados de cuenta detallado</NavLink>
        </NavItem>
      );
    }
  }

  const menuItemEstadoDeCuentaConsolidado = () => {
    let isEnabled = currentUser.roles.includes("ROLE_ADMIN") === true 
      || (currentUser.consultasExportacionesEnabled === true && currentUser.consultasExportacionesEconomicosEnabled === true)
    
    if (isEnabled) {
      return (
        <NavItem>
          <NavLink href="/user/report/estadodecuentaconsolidado">Estados de cuenta consolidado</NavLink>
        </NavItem>
      );
    }
  }

  const menuItemFacturas = () => {
    let isEnabled = currentUser.roles.includes("ROLE_ADMIN") === true 
      || (currentUser.consultasExportacionesEnabled === true && currentUser.consultasExportacionesEconomicosEnabled === true)
    
    if (isEnabled) {
      return (
        <NavItem>
          <NavLink href="/user/report/factura">Facturas</NavLink>
        </NavItem>
      );
    }
  }

  const menuItemDuas = () => {
    let isEnabled = currentUser.roles.includes("ROLE_ADMIN") === true 
      || (currentUser.consultasExportacionesEnabled === true && currentUser.consultasExportacionesDuasEnabled === true)
    
    if (isEnabled) {
      return (
        <NavItem>
          <NavLink href="/user/report/dua">DUAS</NavLink>
        </NavItem>
      );
    }
  }

  const menuItemCalibres = () => {
    let isEnabled = currentUser.roles.includes("ROLE_ADMIN") === true 
      || (currentUser.consultasExportacionesEnabled === true && currentUser.consultasExportacionesFisicosEnabled === true)
    
    if (isEnabled) {
      return (
        <NavItem>
          <NavLink href="/user/report/calibre">Calibres</NavLink>
        </NavItem>
      );
    }
  }

  const menuItemCondicionesTecnicas = () => {
    let isEnabled = currentUser.roles.includes("ROLE_ADMIN") === true 
      || (currentUser.consultasCondicionesTecnicasEnabled === true)
    
    if (isEnabled) {
      return (
        <NavItem>
          <NavLink href="/user/report/condicionestecnicas">Condiciones Técnicas</NavLink>
        </NavItem>
      );
    }
  }

  return (
    <div>
      <Nav vertical>
        {menuItemEstadoDeCuentaDetallado()}

        {menuItemEstadoDeCuentaConsolidado()}

        {menuItemFacturas()}

        {menuItemDuas()}

        {menuItemCalibres()}

        {menuItemCondicionesTecnicas()}
      </Nav>
      <hr />
    </div>
  );
}

export default BoardLeftMenu;