import { 
  GET_USERS_REQUESTED, 
  GET_USERS_RECEIVED, 
  GET_USERS_FAILED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_RECEIVED,
  UPDATE_USER_FAILED,
  DELETE_USER_REQUESTED,
  DELETE_USER_RECEIVED,
  DELETE_USER_FAILED,
  GET_USER_REQUESTED,
  GET_USER_RECEIVED,
  GET_USER_FAILED,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_RECEIVED,
  UPDATE_PASSWORD_REQUESTED,
  GET_ROLES_REQUESTED,
  GET_ROLES_FAILED,
  GET_ROLES_RECEIVED,
  ASSIGN_ROLE_REQUESTED,
  ASSIGN_ROLE_RECEIVED,
  UNASSIGN_ROLE_FAILED,
  UNASSIGN_ROLE_RECEIVED,
  UNASSIGN_ROLE_REQUESTED,
  ASSIGN_ROLE_FAILED
} from '../actions/ActionTypes';
import { PROCESSING, RECEIVED, FAILED } from '../../app-config';

const initialState = {
  user: {},
  users: [],
  status: "",
  roles: []
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_USER_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          user: action.payload,
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_USER_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_USERS_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_USERS_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          users: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_USERS_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case UPDATE_USER_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case UPDATE_USER_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: "Usuario guardado exitosamente"
        }
      );
      break;
        
    case UPDATE_USER_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case DELETE_USER_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case DELETE_USER_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: "Usuario eliminado exitosamente",
          users: state.users.filter(user => user.id !== action.payload)
        }
      );
      break;
        
    case DELETE_USER_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case UPDATE_PASSWORD_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case UPDATE_PASSWORD_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: "Clave actualizada",
        }
      );
      break;
        
    case UPDATE_PASSWORD_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case GET_ROLES_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case GET_ROLES_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          roles: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
        
    case GET_ROLES_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case ASSIGN_ROLE_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case ASSIGN_ROLE_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: "Role asignado",
          users: state.users
            .map(user => {
              if (user.id === action.payload.userId) {
                user.roles = user.roles.concat(action.payload.role);
              }
              return user;
            })
        }
      );
      break;
        
    case ASSIGN_ROLE_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;
  
    case UNASSIGN_ROLE_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case UNASSIGN_ROLE_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: "Role quitado",
          users: state.users
            .map(user => {
              if (user.id === action.payload.userId) {
                user.roles = user.roles.filter(r => r !== action.payload.role);
              }
              return user;
            })
        }
      );
      break;
        
    case UNASSIGN_ROLE_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;
  
    default:
        // nothing to do!
  }

  return state;
}

export default UserReducer;