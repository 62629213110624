/////////////////
// USER ACTIONS
/////////////////
export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
export const GET_USER_RECEIVED = 'GET_USER_RECEIVED';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_RECEIVED = 'GET_USERS_RECEIVED';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_RECEIVED = 'UPDATE_USER_RECEIVED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_RECEIVED = 'DELETE_USER_RECEIVED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const UPDATE_PASSWORD_REQUESTED = 'UPDATE_PASSWORD_REQUESTED';
export const UPDATE_PASSWORD_RECEIVED = 'UPDATE_PASSWORD_RECEIVED';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';

export const GET_ROLES_REQUESTED = 'GET_ROLES_REQUESTED';
export const GET_ROLES_RECEIVED = 'GET_ROLES_RECEIVED';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const ASSIGN_ROLE_REQUESTED = 'ASSIGN_ROLE_REQUESTED';
export const ASSIGN_ROLE_RECEIVED = 'ASSIGN_ROLE_RECEIVED';
export const ASSIGN_ROLE_FAILED = 'ASSIGN_ROLE_FAILED';

export const UNASSIGN_ROLE_REQUESTED = 'UNASSIGN_ROLE_REQUESTED';
export const UNASSIGN_ROLE_RECEIVED = 'UNASSIGN_ROLE_RECEIVED';
export const UNASSIGN_ROLE_FAILED = 'UNASSIGN_ROLE_FAILED';

/////////////////////
// IMPORTS ACTIONS
/////////////////////
export const GET_TABLES_REQUESTED = 'GET_TABLES_REQUESTED';
export const GET_TABLES_RECEIVED = 'GET_TABLES_RECEIVED';
export const GET_TABLES_FAILED = 'GET_TABLES_FAILED';

export const GET_LOGS_REQUESTED = 'GET_LOGS_REQUESTED';
export const GET_LOGS_RECEIVED = 'GET_LOGS_RECEIVED';
export const GET_LOGS_FAILED = 'GET_LOGS_FAILED';

export const IMPORT_TABLE_REQUESTED = 'IMPORT_TABLE_REQUESTED';
export const IMPORT_TABLE_RECEIVED = 'IMPORT_TABLE_RECEIVED';
export const IMPORT_TABLE_FAILED = 'IMPORT_TABLE_FAILED';

export const GET_LAST_SYNC_REQUESTED = 'GET_LAST_SYNC_REQUESTED';
export const GET_LAST_SYNC_RECEIVED = 'GET_LAST_SYNC_RECEIVED';
export const GET_LAST_SYNC_FAILED = 'GET_LAST_SYNC_FAILED';

/////////////////////
// SETTINGS ACTIONS
/////////////////////
export const GET_SETTINGS_REQUESTED = 'GET_SETTINGS_REQUESTED';
export const GET_SETTINGS_RECEIVED = 'GET_SETTINGS_RECEIVED';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';

export const UPDATE_SETTING_REQUESTED = 'UPDATE_SETTING_REQUESTED';
export const UPDATE_SETTING_RECEIVED = 'UPDATE_SETTING_RECEIVED';
export const UPDATE_SETTING_FAILED = 'UPDATE_SETTING_FAILED';

//////////////////////
// URUDOR ACTIONS
//////////////////////
export const GET_ZAFRAS_REQUESTED = 'GET_ZAFRAS_REQUESTED';
export const GET_ZAFRAS_RECEIVED = 'GET_ZAFRAS_RECEIVED';
export const GET_ZAFRAS_FAILED = 'GET_ZAFRAS_FAILED';

export const GET_PROVEEDORES_REQUESTED = 'GET_PROVEEDORES_REQUESTED';
export const GET_PROVEEDORES_RECEIVED = 'GET_PROVEEDORES_RECEIVED';
export const GET_PROVEEDORES_FAILED = 'GET_PROVEEDORES_FAILED';

export const GET_CLIENTES_REQUESTED = 'GET_CLIENTES_REQUESTED';
export const GET_CLIENTES_RECEIVED = 'GET_CLIENTES_RECEIVED';
export const GET_CLIENTES_FAILED = 'GET_CLIENTES_FAILED';

export const GET_VARIEDADES_REQUESTED = 'GET_VARIEDADES_REQUESTED';
export const GET_VARIEDADES_RECEIVED = 'GET_VARIEDADES_RECEIVED';
export const GET_VARIEDADES_FAILED = 'GET_VARIEDADES_FAILED';

export const GET_VAPORES_REQUESTED = 'GET_VAPORES_REQUESTED';
export const GET_VAPORES_RECEIVED = 'GET_VAPORES_RECEIVED';
export const GET_VAPORES_FAILED = 'GET_VAPORES_FAILED';

export const GET_PAISES_REQUESTED = 'GET_PAISES_REQUESTED';
export const GET_PAISES_RECEIVED = 'GET_PAISES_RECEIVED';
export const GET_PAISES_FAILED = 'GET_PAISES_FAILED';

export const GET_ENVASES_REQUESTED = 'GET_ENVASES_REQUESTED';
export const GET_ENVASES_RECEIVED = 'GET_ENVASES_RECEIVED';
export const GET_ENVASES_FAILED = 'GET_ENVASES_FAILED';

export const REPORT_ESTADO_DE_CUENTA_DETALLADO_REQUESTED = 'REPORT_ESTADO_DE_CUENTA_DETALLADO_REQUESTED';
export const REPORT_ESTADO_DE_CUENTA_DETALLADO_RECEIVED = 'REPORT_ESTADO_DE_CUENTA_DETALLADO_RECEIVED';
export const REPORT_ESTADO_DE_CUENTA_DETALLADO_FAILED = 'REPORT_ESTADO_DE_CUENTA_DETALLADO_FAILED';

export const REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_REQUESTED = 'REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_REQUESTED';
export const REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_RECEIVED = 'REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_RECEIVED';
export const REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_FAILED = 'REPORT_ESTADO_DE_CUENTA_CONSOLIDADO_FAILED';

export const REPORT_FACTURAS_REQUESTED = 'REPORT_FACTURAS_REQUESTED';
export const REPORT_FACTURAS_RECEIVED = 'REPORT_FACTURAS_RECEIVED';
export const REPORT_FACTURAS_FAILED = 'REPORT_FACTURAS_FAILED';

export const REPORT_DUAS_REQUESTED = 'REPORT_DUAS_REQUESTED';
export const REPORT_DUAS_RECEIVED = 'REPORT_DUAS_RECEIVED';
export const REPORT_DUAS_FAILED = 'REPORT_DUAS_FAILED';

export const REPORT_CALIBRES_REQUESTED = 'REPORT_CALIBRES_REQUESTED';
export const REPORT_CALIBRES_RECEIVED = 'REPORT_CALIBRES_RECEIVED';
export const REPORT_CALIBRES_FAILED = 'REPORT_CALIBRES_FAILED';