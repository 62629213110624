import { PROCESSING, RECEIVED, FAILED } from '../../app-config';
import { 
  GET_SETTINGS_REQUESTED,
  GET_SETTINGS_RECEIVED, 
  GET_SETTINGS_FAILED,
  UPDATE_SETTING_REQUESTED,
  UPDATE_SETTING_RECEIVED,
  UPDATE_SETTING_FAILED
} from '../actions/ActionTypes';

const initialState = {
  settings: []
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
    
    case GET_SETTINGS_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          settings: [...action.payload],
          status: RECEIVED,
          message: null
        }
      );
      break;
      
    case GET_SETTINGS_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    case UPDATE_SETTING_REQUESTED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: PROCESSING
        }
      );
      break;
      
    case UPDATE_SETTING_RECEIVED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: RECEIVED,
          message: "Configuracion guardada exitosamente",
          settings: state.settings
            .map(setting => {
              if (setting.name === action.payload.name) {
                setting.value = action.payload.value;
              }
              return setting;
            })
        }
      );
      break;
        
    case UPDATE_SETTING_FAILED:
      state = Object.assign({}, state, 
        {
          ...state,
          status: FAILED,
          error: action.payload
        }
      );
      break;

    default:
      // nothing to do!
  }

  return state;
}

export default SettingsReducer;