import { API_URL } from './../../app-config';
import axios from 'axios';
import authHeader from './../../services/auth-header';
import { 
  GET_SETTINGS_RECEIVED,
  GET_SETTINGS_REQUESTED, 
  GET_SETTINGS_FAILED,
  UPDATE_SETTING_REQUESTED,
  UPDATE_SETTING_RECEIVED,
  UPDATE_SETTING_FAILED} from './ActionTypes';
import authService from '../../services/auth.service';

export const getSettings = () => (dispatch) => {
  dispatch({
    type: GET_SETTINGS_REQUESTED
  });

  axios.get(API_URL + '/settings', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_SETTINGS_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_SETTINGS_FAILED,
        payload: error.message
      });
    }
  );
}

export const updateSetting = (name, value) => (dispatch) => {
  authService.validateToken();
  
  dispatch({
    type: UPDATE_SETTING_REQUESTED
  });

  let setting = {
    name: name,
    value: value
  };

  axios.put(API_URL + "/settings/" + name, setting, { headers: authHeader() }).then(
    response => {
      dispatch({
        type: UPDATE_SETTING_RECEIVED,
        payload: {
          name: name,
          value: value
        }
      });
    },
    error => {
      dispatch({
        type: UPDATE_SETTING_FAILED,
        payload: error.response.status === 500 
          ? error.response.data.message 
          : error.response.data
      });
    }
  );
}
