import React, { useState } from 'react';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader, Row, Col, Label } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { updateUserPassword } from '../../store/actions/user.action';
import { connect } from 'react-redux';
import MyButton from '../custom/my-button.component';

const mapStateToProps = state => {
  return {
    status: state.adminUser.status
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUserPassword: (userId, newPassword) => {dispatch(updateUserPassword(userId, newPassword))}
});

const AdminUpdatePassword = (props) => {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  
  const handleSubmit = (values) => {
    props.updateUserPassword(props.user.id, values.password);
  }

  const toggleConfirmDialog = () => setModal(!modal);

  const initialValues = {
      password: "",
      confirmPassword: ""
  }

  const validateForm = values => {
    const errors = {};

    if (!values.password) {
      errors.password = "Clave vacía";
    } else if (values.password.length < 6) {
      errors.password = "Tiene que tener mas de 4 caracteres";
    } else if (values.password.length > 20) {
      errors.password = "Tienen que tener menos de 20 caracateres";
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Las claves no coinciden"
    }

    return errors;
  }

  return (
    <>
      <MyButton 
        color="warning"
        colorSpinner="light"
        icon={faKey}
        status={props.status}
        onClick={toggleConfirmDialog}
      />

      <Modal isOpen={modal} toggle={toggle}>
      
        <ModalHeader toggle={toggle}>Cambiar clave de {props.user.username}</ModalHeader>

        <Formik 
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={
              (values) => {
                handleSubmit(values);
              }
            }
          >
          <Form>
        
            <ModalBody>
              <Row className="form-group">
                <Col md={6}>
                  <Label htmlFor="password">Clave</Label>
                  <Field type="password" name="password" className="form-control" />
                  <ErrorMessage name="password" component="div" className="text-danger" />
                </Col>

                <Col md={6}>
                  <Label htmlFor="confirmPassword">Confirmar Clave</Label>
                  <Field type="password" name="confirmPassword" className="form-control" />
                  <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Row className="form-group">
                <Col className="d-flex justify-content-end">
                  <MyButton 
                    type="submit"
                    color="primary"
                    colorSpinner="light"
                    value="Guardar"
                    status={props.status}
                    />

                  <Button color="secondary ml-2" onClick={toggle}>Cancelar</Button>
                </Col>
              </Row>
            </ModalFooter>
          </Form>

        </Formik>
      </Modal>
    </>
  );

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUpdatePassword);