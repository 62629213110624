import React, { Component } from "react";

import { Row, Nav, NavItem, NavLink, Col, Container } from 'reactstrap';
import { Switch } from "react-router-dom";
import PrivateRoute from "../custom/private-route.component";

import AdminHome from './admin-home.component';
import AdminUsers from './admin-users.component';
import AdminUser from './admin-user.component';
import AdminSettings from "./admin-settings.component";
import AdminImports from "./admin-imports.component";


class BoardAdmin extends Component {

  renderLeftMenu() {
    return (
      <div>
        <Nav vertical>
          <NavItem>
            <NavLink href="/admin/users">Usuarios</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/admin/imports">Importar MDB</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/admin/settings">Configuraciones</NavLink>
          </NavItem>
        </Nav>
        <hr />
      </div>
    );
  }

  render() {
    return (
      <Container fluid={true}>

        <Row>
          <Col md="3">
            {this.renderLeftMenu()}
          </Col>
          <Col md="9">
            <Switch>
              <PrivateRoute exact path="/admin" component={AdminHome} />
              <PrivateRoute exact path="/admin/users" component={AdminUsers} />
              <PrivateRoute exact path="/admin/imports" component={AdminImports} />
              <PrivateRoute exact path="/admin/settings" component={AdminSettings} />
              <PrivateRoute exact path="/admin/users/new" component={AdminUser} />
              <PrivateRoute path="/admin/users/edit/:userId" component={AdminUser} />
            </Switch>
          </Col>
        </Row>

      </Container>
    );
  }
}

export default BoardAdmin;