import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class AdminHome extends Component {

  render() {
    return (
      <Breadcrumb className="mt-2">
        <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
        <BreadcrumbItem active>Admin</BreadcrumbItem>
      </Breadcrumb>
    );
  }
}

export default AdminHome;