import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import UserReducer from './reducers/user.reducer';
import SettingsReducer from './reducers/settings.reducer';
import ImportsReducer from './reducers/imports.reducer';
import UrudorReducer from './reducers/urudor.reducer';

const combinedReducers = combineReducers(
  {
    adminUser: UserReducer,
    imports: ImportsReducer,
    settings: SettingsReducer,
    urudor: UrudorReducer
  }
);


const store = createStore(combinedReducers, {}, applyMiddleware(thunk));

export default store;