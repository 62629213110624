
import React from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
import { Label } from 'reactstrap'

function DatePicker (props) {
  const { label, name, ...rest } = props
  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <Field name={name} className="form-control">
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={val => setFieldValue(name, val)}
              dateFormat="dd-MM-yyyy"
            />
          )
        }}
      </Field>
      <ErrorMessage name={name} component="div" className="text-danger" />
    </>
  )
}

export default DatePicker;