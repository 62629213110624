import React, { Component } from 'react';
import { getMdbTables, getLogs, importTable } from '../../store/actions/imports.action';
import { connect } from 'react-redux';
import { Table, Input, Button, Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap';
import { faSync, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BoxError from '../custom/box-error.component';
import BoxSuccess from '../custom/box-success.component';

const mapStateToProps = state => {
  return {
    mdbTables: ['Todas'].concat(state.imports.mdbTables).map(t => ({name: t, truncate: false})),
    logs: state.imports.logs,
    status: state.imports.status,
    error: state.imports.error,
    message: state.imports.message
  }
};

const mapDispatchToProps = (dispatch) => ({
  getMdbTables: () => {dispatch(getMdbTables())},
  getLogs: () => {dispatch(getLogs())},
  importTable: (tableName, truncate) => {dispatch(importTable(tableName, truncate))}
});

class AdminImports extends Component {

  componentDidMount = () => {
    this.props.getMdbTables();
    this.syncLogs();
  };

  syncLogs = () => {
    this.props.getLogs();
    
    setInterval(() => {
      this.props.getLogs();
    }, 5000);
  };

  renderLogRow = () => {
    if (this.props.logs) {
      return (
        this.props.logs.map(log => {

          let statusIcon;
          if (log.status === 'RUNNING') {
            statusIcon = <Spinner size="sm" color="primary" />
          } else if (log.status === 'FINISHED') {
            statusIcon = <FontAwesomeIcon icon={faCheck} /> 
          } else {
            statusIcon = <FontAwesomeIcon icon={faExclamationTriangle} /> 
          }

          return (
            <tr key={log.id}>
              <td>{log.startDateTime}</td>
              <td>{log.endDateTime}</td>
              <td>{log.type === 'MANUAL' ? 'Manual' : 'Automatico'}</td>
              <td>{statusIcon}</td>
              <td>{log.message}</td>
            </tr>
          );
        })
      );
    }
  };

  renderLogsTable = () => {
    if (this.props.logs) {
      return (
        <Table dark size="sm">
          <thead>
            <tr>
              <th>Inicio</th>
              <th>Terminado</th>
              <th>Tipo</th>
              <th></th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>
            {this.renderLogRow()}
          </tbody>
      </Table>      
      );
    }
  }

  handleClick = (event, mdbTable) => {
    this.props.importTable(mdbTable.name, mdbTable.truncate);
  };

  renderRowsTable = () => {
    return(
      this.props.mdbTables.map(mdbTable => {
        return (
          <tr key={mdbTable.name}>
            <td>{mdbTable.name}</td>
            
            <td className="d-flex justify-content-center">
              <Input type="checkbox" id="checkbox2" 
                onClick={() => mdbTable.truncate = !mdbTable.truncate} 
                defaultChecked={false}/>
            </td>

            <td>
              <Button color="primary" onClick={(event) => this.handleClick(event, mdbTable)}>
                <FontAwesomeIcon icon={faSync} /> 
              </Button>
            </td>
          </tr>
        );
      })
    );
  };

  renderAvailableTablesToImport = () => {
    return (
      <Table hover size="sm">
        <thead>
          <tr>
            <th>Tabla</th>
            <th className="d-flex justify-content-center">Vaciar antes de Sync</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.renderRowsTable()}
        </tbody>
      </Table>      
    );
  };

  renderBreadcrumb = () => {
    return (
      <div>
        <Breadcrumb className="mt-2">
          <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/admin">Admin</a></BreadcrumbItem>
          <BreadcrumbItem active>Importar</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  };

  render() {
    return (
      <>
        { this.renderBreadcrumb() }

        <BoxError status={this.props.status} error={this.props.error} />
        <BoxSuccess status={this.props.status} message={this.props.message}/>
        
        {this.renderLogsTable()}

        {this.renderAvailableTablesToImport()}
      </>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminImports);

