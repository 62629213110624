import React from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import BoardLeftMenu from './board-left-menu.component';

const UserHome = () => {
  const renderBreadCrumb = () => {
    return (
      <Breadcrumb className="mt-2">
        <BreadcrumbItem><a href="/home">Inicio</a></BreadcrumbItem>
      </Breadcrumb>
    );
  }
  return (
    <Row>
      <Col md="3">
        <BoardLeftMenu />
      </Col>
      <Col md="9">
        {renderBreadCrumb()}
      </Col>
    </Row>
  );
}

export default UserHome;