import React from 'react';
import { Row, Col, UncontrolledAlert } from 'reactstrap';
import { FAILED } from '../../app-config';

const BoxError = props =>{
  if (props.status === FAILED) {
    return (
      <Row md={12}>
        <Col>
          <UncontrolledAlert color="danger">
            {props.error}
          </UncontrolledAlert>
        </Col>
      </Row>
    );
  } else {
    return (<></>);
  }
}

export default BoxError;