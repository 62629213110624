import { API_URL } from './../../app-config';
import axios from 'axios';
import authHeader from './../../services/auth-header';
import authService from '../../services/auth.service';
import { 
  GET_TABLES_REQUESTED,
  GET_TABLES_RECEIVED,
  GET_TABLES_FAILED,
  GET_LOGS_REQUESTED,
  GET_LOGS_RECEIVED,
  GET_LOGS_FAILED,
  IMPORT_TABLE_REQUESTED,
  IMPORT_TABLE_RECEIVED,
  IMPORT_TABLE_FAILED,
  GET_LAST_SYNC_REQUESTED,
  GET_LAST_SYNC_RECEIVED,
  GET_LAST_SYNC_FAILED
} from './ActionTypes';

export const getMdbTables = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_TABLES_REQUESTED
  });

  axios.get(API_URL + '/import/tables', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_TABLES_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_TABLES_FAILED,
        payload: error.message
      });
    }
  );
  
}

export const getLogs = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_LOGS_REQUESTED
  });

  axios.get(API_URL + '/import/logs', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_LOGS_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_LOGS_FAILED,
        payload: error.message
      });
    }
  );
  
}

export const findLastSync = () => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: GET_LAST_SYNC_REQUESTED
  });

  axios.get(API_URL + '/import/logs/last', { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: GET_LAST_SYNC_RECEIVED,
        payload: response.data
      });
    },
    error => {
      dispatch({
        type: GET_LAST_SYNC_FAILED,
        payload: error.message
      });
    }
  );
  
}

export const importTable = (tableName, truncate) => (dispatch) => {
  authService.validateToken();

  dispatch({
    type: IMPORT_TABLE_REQUESTED
  });

  let body = {
    truncate: truncate
  };

  let url = tableName === 'Todas'
    ? API_URL + '/import'
    : API_URL + '/import/' + tableName;

  axios.post(url, body, { headers: authHeader() })
  .then(
    response => {
      dispatch({
        type: IMPORT_TABLE_RECEIVED,
      });
    },
    error => {
      dispatch({
        type: IMPORT_TABLE_FAILED,
        payload: error.message
      });
    }
  );
  
}